// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

.kt-error-v3 {
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    .kt-error_container {
        .kt-error_number {
            > h1 {
                font-size: 15.7rem;
                margin-left: 7.85rem;
                margin-top: 11.4rem;
                font-weight: 500;
                -webkit-text-stroke-width: 0.35rem;
                -moz-text-stroke-width: 0.35rem;
                text-stroke-width: 0.35rem;

                color:#A3DCF0;
                -webkit-text-stroke-color: white;
                -moz-text-stroke-color: white;
                text-stroke-color: white;

                @include kt-hack-ie {
                    color: white;
                }
            }
        }

        .kt-error_title {
            margin-left: 7.85rem;
            font-size: 2.5rem;
            font-weight: 700;
            color: kt-base-color(label, 4);
        }

        .kt-error_subtitle {
            margin-left: 7.85rem;
            margin-top: 3.57rem;
            font-size: 1.8rem;
            font-weight: 700;
            color: kt-base-color(label, 3);
        }

        .kt-error_description {
            margin-left: 7.85rem;
            font-size: 1.4rem;
            font-weight: 500;
            color: kt-base-color(label, 2);
        }
    }
}

@include kt-mobile() {
    .kt-error-v3 {
        .kt-error_container {
            .kt-error_number {
                > h1 {
                    font-size: 8rem;
                    margin-left: 4rem;
                    margin-top: 3.5rem;
                }
            }

            .kt-error_title {
                margin-left: 4rem;
            }

            .kt-error_subtitle {
                margin-left: 4rem;
                padding-right: 0.5rem;
            }

            .kt-error_description {
                margin-left: 4rem;
                padding-right: 0.5rem;
            }
        }
    }
}

@import "@fontsource/kumbh-sans";

.error-3-display {
	p {
		font-family: 'Kumbh Sans';
		color: #222222;
	}

	@media screen and (max-width: 1200px) {
		.container-error {
			flex-direction: column;
		}

		.container-error-2 {
			margin-top: 24px;
		}
	}
	
	.container-error {
		display: flex;
		padding: 13vw;
		height: 100%;
		width: 100%;
		align-items: center;
		justify-content: space-around;
		background-color: #B8C6FF;
		overflow: hidden;
	
		.container-error-1 {
			max-width: 70vw;
		}
	
		.container-error-2 {
			margin-left: 40px;
		}
	}
	
	.error-code {
		font-size: 24px;
		font-weight: 400;
		color: #000;
		margin-bottom: 56px;
	}
	
	.error-title {
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 56px;
	}
	
	.error-text {
		font-size: 20px;
		margin-bottom: 56px;
	}
	
	.error-logo {
		margin-bottom: 56px;
		max-height: 56px !important;
		max-width: 70vw;
		aspect-ratio: calc(337/56) !important;
	}
	
	.error-button {
		height: 48px;
		width: fit-content;
		background-color: #284EEA;
		border-radius: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px 16px;
		color: #FFF;
		font-weight: 500;
		font-size: 16px;
	}
	
	.error-button:hover {
		background-color: #7084fc;
		transition: 0.2s ease-in-out, opacity 0.1s ease-in-out;
	}
	
	
	
	@media (max-height: 768px) {
		.error-logo, .error-text, .error-title, .error-code {
			margin-bottom: 32px;
		}
	}
}